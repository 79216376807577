import { MainRoutes } from 'shared/config/routeConfig/index';

export enum LoyaltyPath {
    CUSTOMERS = 'customers',
    CUSTOMER_DETAILS = 'customers/', // :id
}

export const loyaltyRoutes: Record<LoyaltyPath, string> = {
  [LoyaltyPath.CUSTOMERS]: MainRoutes.LOYALTY + LoyaltyPath.CUSTOMERS,
  [LoyaltyPath.CUSTOMER_DETAILS]: MainRoutes.LOYALTY + LoyaltyPath.CUSTOMER_DETAILS,
};
