import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from 'shared/lib/getError';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { $api } from 'shared/api/api';
import { User } from '../types/User';

export const loadUser = createAsyncThunk<
    User,
    void,
    ThunkConfig<string>
    >(
      'user/checkAuth',
      async (_, { rejectWithValue }) => {
        try {
          const response = await $api.get<User>('/api/auth/currentUser');

          if (!response.data) {
            throw new Error('empty resposne');
          }

          return response.data;
        } catch (e) {
          return rejectWithValue(getError(e));
        }
      },
    );
