import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'entities/User';
import MenuItem from '../MenuItem/MenuItem';
import cl from './Menu.module.css';
import { menuItems } from '../../config/menuItems';

function Menu() {
  const user = useSelector(getUserSelector);

  const filteredMenuItems = useMemo(() => menuItems.filter((x) => x.permittedRoles.some((y) => user?.roles.includes(y))), [user]);

  if (!user) return null;

  return (
    <nav className={cl.menu}>
      {filteredMenuItems.map((i) => (
        <MenuItem
          key={i.name}
          name={i.name}
          type={i.type}
        />
      ))}
    </nav>
  );
}

export default Menu;
