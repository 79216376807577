import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from 'shared/lib/getError';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { $api } from 'shared/api/api';
import { WbSettings } from 'entities/Settings/Wildberries';

export const syncWbPrices = createAsyncThunk<
    void,
    void,
    ThunkConfig<string>>(
      'settings/syncWbPrices',
      // eslint-disable-next-line consistent-return
      async (_, thunkAPI) => {
        try {
          const response = await $api.post<WbSettings>('/api/wildberries/prices/sync', {});
        } catch (e) {
          return thunkAPI.rejectWithValue(getError(e));
        }
      },
    );
