import { MainRoutes } from 'shared/config/routeConfig/index';

export enum SettingsPath {
    ORGANIZATIONS = 'organizations',
    ORGANIZATIONS_DETAILS = 'organizations/',
    LOGS = 'logs',
    EVENTLOGS = 'eventLogs',
    USERS = 'users',
    MOYSKLAD = 'moySklad',
    OZON = 'ozon',
    YANDEX = 'yandex',
    WILDBERRIES = 'wildberries',
    MEGAMARKET = 'megaMarket',
}

export const settingsRoutes: Record<SettingsPath, string> = {
  [SettingsPath.ORGANIZATIONS]: MainRoutes.SETTINGS + SettingsPath.ORGANIZATIONS,
  [SettingsPath.ORGANIZATIONS_DETAILS]: MainRoutes.SETTINGS + SettingsPath.ORGANIZATIONS_DETAILS,
  [SettingsPath.LOGS]: MainRoutes.SETTINGS + SettingsPath.LOGS,
  [SettingsPath.EVENTLOGS]: MainRoutes.SETTINGS + SettingsPath.EVENTLOGS,
  [SettingsPath.USERS]: MainRoutes.SETTINGS + SettingsPath.USERS,
  [SettingsPath.MOYSKLAD]: MainRoutes.SETTINGS + SettingsPath.MOYSKLAD,
  [SettingsPath.OZON]: MainRoutes.SETTINGS + SettingsPath.OZON,
  [SettingsPath.YANDEX]: MainRoutes.SETTINGS + SettingsPath.YANDEX,
  [SettingsPath.WILDBERRIES]: MainRoutes.SETTINGS + SettingsPath.WILDBERRIES,
  [SettingsPath.MEGAMARKET]: MainRoutes.SETTINGS + SettingsPath.MEGAMARKET,
};
