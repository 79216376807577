import classNames from 'classnames';
import React from 'react';
import cl from './DateRange2Control.module.css';
import { DateRangeState } from '../../types/DateRangeState';

interface DateRange2ControlProps {
  state: DateRangeState;
  onPrevClick: () => void;
  onNextClick: () => void;
  onResetClick: () => void;
}

export const DateRange2Control = (props: DateRange2ControlProps) => {
  const {
    state, onPrevClick, onNextClick, onResetClick,
  } = props;

  return (
    <div className={cl.control}>
      <div className={classNames(cl.controlBtn, cl.controlBtnBack)} onClick={onPrevClick} />
      <div className={cl.controlText} onClick={onResetClick}>{state.text}</div>
      <div className={classNames(cl.controlBtn, cl.controlBtnForward)} onClick={onNextClick} />
    </div>
  );
};
