import React from 'react';
import cl from 'widgets/Header/ui/SubMenu/SubMenu.module.css';
import { useSelector } from 'react-redux';
import { getMenuCurrentType } from 'entities/Menu';
import { getUserSelector } from 'entities/User';
import { SubMenuItem } from '../SubMenuItem/SubMenuItem';
import { menuItems } from '../../config/menuItems';

function SubMenu() {
  const user = useSelector(getUserSelector);
  const currentMenuItemType = useSelector(getMenuCurrentType);

  if (!user) return null;
  if (!currentMenuItemType) return null;

  const menuItem = menuItems.find((menuItem) => menuItem.type === currentMenuItemType);

  if (!menuItem) return null;

  return (
    <div className={cl.subMenu}>
      {menuItem.children.map((c) => (
        <SubMenuItem
          key={c.path}
          to={c.path}
          name={c.name}
        />
      ))}
    </div>
  );
}

export default SubMenu;
