import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { DateRangeValue } from 'shared/types/DateRangeValue';
import { getInitialState } from '../../lib/getInitialState';
import {
  dateRangeStateBuilderByMode,
  nextDateRangeState,
  prevDateRangeState,
} from '../../lib/dateRangeStateBuilder';
import { DateRangeSupportedModes } from '../../types/DateRangeSupportedModes';
import { DateRangeState } from '../../types/DateRangeState';
import { DateRange2Control } from '../DateRange2Control/DateRange2Control';
import { DateRange2ModeSelector } from '../DateRange2ModeSelector/DateRange2ModeSelector';
import cl from './DateRange2.module.css';
import { DateRangeMode } from '../../types/DateRangeMode';

interface DateRange2Props {
  className?: string;
  supportedModes: DateRangeSupportedModes;
  defaultMode?: DateRangeMode;
  value?: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
}

export const DateRange2 = (props: DateRange2Props) => {
  const {
    className,
    supportedModes,
    defaultMode = DateRangeMode.None,
    value,
    onChange,
  } = props;

  const [state, setState] = useState<DateRangeState>(() => getInitialState(defaultMode, value));

  const onDateRangeModeChange = (mode: DateRangeMode) => {
    setState(dateRangeStateBuilderByMode(mode));
  };

  const onPrevClick = () => {
    setState(prevDateRangeState(state));
  };

  const onNextClick = () => {
    setState(nextDateRangeState(state));
  };

  const onResetClick = () => {
    setState(dateRangeStateBuilderByMode(DateRangeMode.None));
  };

  useEffect(() => {
    if (state.value === value) return;
    onChange(state.value);
  }, [state]);

  return (
    <div className={classNames(cl.DateRange2, className)}>
      {state.mode === DateRangeMode.None
        ? <DateRange2ModeSelector onChange={onDateRangeModeChange} supportedModes={supportedModes} />
        : (
          <DateRange2Control
            state={state}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onResetClick={onResetClick}
          />
        )}
    </div>
  );
};
