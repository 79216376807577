import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getError } from 'shared/lib/getError';
import { OrdersResult } from 'entities/Marktetplaces/Orders/model/types/OrdersResult';
import { getOrdersFilter } from 'entities/Marktetplaces/Orders/model/selectors/ordersSelectors';
import { dateRangeValueToParams } from 'shared/lib/dateRangeValueHelper';

export const fetchOrders = createAsyncThunk<OrdersResult, void, ThunkConfig<string>>(
  'pages/Orders/fetchOrders',
  async (_, thunkAPI) => {
    const {
      rejectWithValue, extra, getState,
    } = thunkAPI;
    try {
      const filter = getOrdersFilter(getState());
      if (!filter) throw Error('Empty filter');

      const params = {
        ...filter,
        ...dateRangeValueToParams(filter.dateRange),
      };

      // @ts-ignore
      delete params.dateRange;

      const response = await extra.api.get<OrdersResult>('/api/ksao/orders', {
        params,
      });

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);
