import React, { Suspense } from 'react';
import { AppRouter } from 'app/providers/AppRouter';
import { Header } from 'widgets/Header';
import { Loader } from 'shared/ui/Loader';
import 'app/styles/index.css';
import 'reactjs-popup/dist/index.css';

function App() {
  return (
    <>
      <Header />
      <main className="appContent">
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      </main>
    </>
  );
}

export default App;
