import { RouteProps } from 'react-router-dom';
import { LoyaltyPath, loyaltyRoutes } from 'shared/config/routeConfig/loyaltyRouteConfig';
import { CustomersPage } from 'pages/Loyalty/CustomersPage';
import { CustomerDetailsPage } from 'pages/Loyalty/CustomerDetailsPage';

export const loyaltyRoutesConfig: Record<LoyaltyPath, RouteProps> = {
  [LoyaltyPath.CUSTOMERS]: {
    path: loyaltyRoutes[LoyaltyPath.CUSTOMERS],
    element: <CustomersPage />,
  },
  [LoyaltyPath.CUSTOMER_DETAILS]: {
    path: `${loyaltyRoutes[LoyaltyPath.CUSTOMER_DETAILS]}:id`,
    element: <CustomerDetailsPage />,
  },
};
