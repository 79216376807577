import { isSuccess, RemoteData } from '@devexperts/remote-data-ts';
import { isFailure, isPending } from '@devexperts/remote-data-ts/remote-data';

export const getRemoteDataValue = <T1, T2>(data: RemoteData<T1, T2> | undefined) => {
  if (data && isSuccess(data)) return data.value;

  return undefined;
};

export const getRemoteDataIsPending = <T1, T2>(data: RemoteData<T1, T2> | undefined) => {
  if (data && isPending(data)) return true;

  return false;
};

export const getRemoteDatasIsPending = (datas: (RemoteData<any, any> | undefined)[]) => {
  for (let i = 0; i < datas.length; i += 1) {
    if (getRemoteDataIsPending(datas[i])) return true;
  }

  return false;
};

export const getRemoteDataError = <T1, T2>(data: RemoteData<T1, T2> | undefined) => {
  if (data && isFailure(data)) return data.error;

  return undefined;
};

export const getRemoteDatasError = (datas: (RemoteData<any, any> | undefined)[]) => {
  for (let i = 0; i < datas.length; i += 1) {
    const error = getRemoteDataError(datas[i]);
    if (error) return error;
  }

  return undefined;
};
