import { RouteProps } from 'react-router-dom';
import { OzonPath, ozonRoutes } from 'shared/config/routeConfig/ozonRouteConfig';
import {
  FboPostingsPage, FboStocksPage, OrderOperationsPage,
  OzonRealizationReportsPage, OzonSalesReportPage,
} from 'pages/Ozon';
import { OzonActionsPage } from 'pages/Ozon/OzonActionsPage';

export const ozonRoutesConfig: Record<OzonPath, RouteProps> = {
  [OzonPath.STOCKS]: {
    path: ozonRoutes[OzonPath.STOCKS],
    element: <FboStocksPage />,
  },
  [OzonPath.OPERATIONS]: {
    path: ozonRoutes[OzonPath.OPERATIONS],
    element: <OrderOperationsPage />,
  },
  [OzonPath.FBOPOSTINGS]: {
    path: ozonRoutes[OzonPath.FBOPOSTINGS],
    element: <FboPostingsPage />,
  },
  [OzonPath.REALIZATION_REPORTS]: {
    path: ozonRoutes[OzonPath.REALIZATION_REPORTS],
    element: <OzonRealizationReportsPage />,
  },
  [OzonPath.SALES_REPORT]: {
    path: ozonRoutes[OzonPath.SALES_REPORT],
    element: <OzonSalesReportPage />,
  },
  [OzonPath.ACTIONS]: {
    path: ozonRoutes[OzonPath.ACTIONS],
    element: <OzonActionsPage />,
  },
};
