import React from 'react';
import cl from 'widgets/Header/ui/User/User.module.css';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'entities/User';

function User() {
  const user = useSelector(getUserSelector);

  if (!user) {
    return null;
  }

  return (
    <div className={cl.user}>
      <div className={cl.column}>
        <div className={cl.name}>
          {user.firstName}
          {' '}
          {user.lastName}
        </div>
        <div className={cl.login}>{user.login}</div>
      </div>
      <div className={cl.avatar} />
    </div>
  );
}

export default User;
