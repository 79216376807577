import axios, { AxiosRequestConfig } from 'axios';
import { refresh } from './auth/authService';
import { authInterceptor } from './interceptors/authInterceptor';

export const $api = axios.create({
  withCredentials: true,
  // baseURL: process.env.REACT_APP_API_URL
});

$api.interceptors.request.use(authInterceptor);
$api.interceptors.response.use(
  (config: AxiosRequestConfig) => config,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !originalRequest._isRetry) {
      originalRequest._isRetry = true;

      try {
        await refresh();
      } catch (e) {
        console.log(e);
      }

      return $api.request(originalRequest);
    }

    throw error;
  },
);
