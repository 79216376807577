import { MainRoutes } from 'shared/config/routeConfig/index';

export enum OzonPath {
    STOCKS = 'stocks',
    OPERATIONS = 'operations',
    FBOPOSTINGS = 'fboPostings',
    REALIZATION_REPORTS = 'realizationReports',
    SALES_REPORT = 'salesReport',
    ACTIONS = 'actions',
}

export const ozonRoutes: Record<OzonPath, string> = {
  [OzonPath.STOCKS]: MainRoutes.OZON + OzonPath.STOCKS,
  [OzonPath.OPERATIONS]: MainRoutes.OZON + OzonPath.OPERATIONS,
  [OzonPath.FBOPOSTINGS]: MainRoutes.OZON + OzonPath.FBOPOSTINGS,
  [OzonPath.REALIZATION_REPORTS]: MainRoutes.OZON + OzonPath.REALIZATION_REPORTS,
  [OzonPath.SALES_REPORT]: MainRoutes.OZON + OzonPath.SALES_REPORT,
  [OzonPath.ACTIONS]: MainRoutes.OZON + OzonPath.ACTIONS,
};
