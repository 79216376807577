import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from 'pages/Login';
import { useSelector } from 'react-redux';
import { NotFoundPage } from 'pages/NotFound';
import { appRoutes } from 'app/providers/AppRouter/config';
import { getUserSelector } from 'entities/User';
import { RegisterPage } from 'pages/Register';

export function AppRouter() {
  const user = useSelector(getUserSelector);

  if (!user) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage />} />
        <Route path="auth/registerByInvite/:invite" element={<RegisterPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      {appRoutes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={element}
        />
      ))}
    </Routes>
  );
}
