import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from 'shared/lib/getError';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getCurrentUser, refresh } from 'shared/api/auth/authService';
import { User } from '../types/User';

export const checkAuth = createAsyncThunk<
    User,
    void,
    ThunkConfig<string>
    >(
      'user/checkAuth',
      async (_, { rejectWithValue }) => {
        try {
          await refresh();
          const user = await getCurrentUser();
          return user;
        } catch (e) {
          return rejectWithValue(getError(e));
        }
      },
    );
