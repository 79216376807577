import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth, getUserIsLoadingSelector } from 'entities/User';
import { Loader } from 'shared/ui/Loader';

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getUserIsLoadingSelector);

  useEffect(() => {
    // @ts-ignore
    dispatch(checkAuth());
  }, [dispatch]);

  if (isLoading) return (<Loader />);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<>{children}</>);
};
