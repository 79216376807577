import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from 'shared/lib/getError';
import { $api } from 'shared/api/api';
import { WbSettings } from 'entities/Settings/Wildberries';
import { ThunkConfig } from 'app/providers/StoreProvider';

export const syncWbOrders = createAsyncThunk<
    void,
    void,
    ThunkConfig<string>>(
      'settings/syncWbOrders',
      // eslint-disable-next-line consistent-return
      async (_, thunkAPI) => {
        try {
          await $api.post<WbSettings>('/api/wildberries/orders/syncNew', {});
        } catch (e) {
          return thunkAPI.rejectWithValue(getError(e));
        }
      },
    );
