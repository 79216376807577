import dayjs from 'dayjs';
import { DateRangeValue, emptyDateRangeValue } from 'shared/types/DateRangeValue';
import {
  dateRangeValueBuilder,
  nextDateRangeValue,
  prevDateRangeValue,
} from 'shared/ui/DateRange2/lib/dateRangeValueBuilder';
import { DateRangeMode } from '../types/DateRangeMode';
import { DateRangeState } from '../types/DateRangeState';

export function dateRangeStateBuilder(mode: DateRangeMode, range: DateRangeValue) {
  return {
    value: range,
    mode,
    text: getDateRangeValueDescription(mode, range),
  };
}

export function dateRangeStateBuilderByMode(mode: DateRangeMode) {
  const range = dateRangeValueBuilder(mode);
  return {
    value: range,
    mode,
    text: getDateRangeValueDescription(mode, range),
  };
}

export function nextDateRangeState(state: DateRangeState) {
  const range = nextDateRangeValue(state.mode, state.value);
  return dateRangeStateBuilder(state.mode, range);
}

export function prevDateRangeState(state: DateRangeState) {
  const range = prevDateRangeValue(state.mode, state.value);
  return dateRangeStateBuilder(state.mode, range);
}

function getDateRangeValueDescription(mode: DateRangeMode, range: DateRangeValue): string | undefined {
  if (range.isEmpty) return '---';

  switch (mode) {
    case DateRangeMode.Day:
      if (range.from.isSame(dayjs(), 'day')) {
        return range.from.format('[Сегодня, ] D MMMM');
      }

      if (range.from.isSame(dayjs().add(-1, 'd'), 'day')) {
        return range.from.format('[Вчера, ] D MMMM');
      }

      return range.from.format('D MMMM');
    case DateRangeMode.Week:
      return range.from.format('D') + range.to.format('[-]D MMMM');
    case DateRangeMode.Month:
      return range.from.format('MMMM');
    case DateRangeMode.Year:
      return range.from.format('YYYY');
    default: return '---';
  }
}
