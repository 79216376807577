import { createAsyncThunk } from '@reduxjs/toolkit';
import { getError } from 'shared/lib/getError';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { $api } from 'shared/api/api';
import { WbSettings } from 'entities/Settings/Wildberries';

export const updateWbSettings = createAsyncThunk<
    WbSettings,
  WbSettings,
    ThunkConfig<string>>(
      'settings/updateWbSettings',
      async (wbSettings, thunkAPI) => {
        try {
          const response = await $api.post<WbSettings>(
            '/api/wildberries/settings',
            wbSettings,
          );

          return response.data;
        } catch (e) {
          return thunkAPI.rejectWithValue(getError(e));
        }
      },
    );
