import { AxiosRequestConfig } from 'axios';
import { getToken } from 'shared/api/token';

export const authInterceptor = (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers.authorization = `Bearer ${getToken()}`;
  }

  return config;
};
