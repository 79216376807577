export enum DateRangeSupportedModes {
  Year = 1,
  Month = 2,
  Week = 4,
  Day = 8,

  YearMonth = 3,
  YearMonthWeek = 7,
  YearMonthWeekDay = 15,

  MonthWeek = 6,
  MonthWeekDay = 14,
}
