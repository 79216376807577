import React, { FC } from 'react';
import classNames from 'classnames';
import { getMenuCurrentType, menuActions, MenuItemType } from 'entities/Menu';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import cl from './MenuItem.module.css';

interface MenuItemProps {
    name: string;
    type: MenuItemType;
}

const MenuItem: FC<MenuItemProps> = ({ name, type }) => {
  const dispatch = useAppDispatch();
  const menu = useSelector(getMenuCurrentType);

  const onClick = (menuItemType: MenuItemType) => {
    dispatch(menuActions.setMenuItem(menuItemType));
  };

  return (
    <div
      className={classNames(
        cl.MenuItem,
        type === menu && cl.itemActive,
      )}
      onClick={() => onClick(type)}
    >
      <div
        className={classNames(
          cl.itemIcon,
          type === MenuItemType.Marketplaces && cl.itemTypeMarketplaces,
          type === MenuItemType.Finance && cl.itemTypeFinance,
          type === MenuItemType.Settings && cl.itemTypeSettings,
          type === MenuItemType.Reports && cl.itemTypeReports,
          type === MenuItemType.Loyalty && cl.itemTypeLoyalty,
          type === MenuItemType.Ozon && cl.ozon,
          type === MenuItemType.Wb && cl.wb,
        )}
      />
      <div className={cl.itemText}>{name}</div>
    </div>
  );
};

export default MenuItem;
