import { RouteProps } from 'react-router-dom';
import { UsersPage } from 'pages/Settings/UsersPage';
import { SettingsPath, settingsRoutes } from 'shared/config/routeConfig/settingsRouteConfig';
import { WildberriesPage } from 'pages/Settings/WildberriesPage';
import { MoySkladSettingsPage } from 'pages/Settings/MoySklad';
import { OzonSettingsPage } from 'pages/Settings/OzonSettingsPage';
import { EventLogPage } from 'pages/Settings/EventLogPage';
import { FileLogPage } from 'pages/Settings/FileLogPage';
import { YandexPage } from 'pages/Settings/YandexPage';
import { MegaMarketPage } from 'pages/Settings/MegaMarketPage';
import { OrganizationPage, OrganizationsSettingsPage } from 'pages/Settings/OrganizationsPage';

export const settingsRoutesConfig: Record<SettingsPath, RouteProps> = {
  [SettingsPath.ORGANIZATIONS]: {
    path: settingsRoutes[SettingsPath.ORGANIZATIONS],
    element: <OrganizationsSettingsPage />,
  },
  [SettingsPath.ORGANIZATIONS_DETAILS]: {
    path: `${settingsRoutes[SettingsPath.ORGANIZATIONS_DETAILS]}:id`,
    element: <OrganizationPage />,
  },
  [SettingsPath.LOGS]: {
    path: settingsRoutes[SettingsPath.LOGS],
    element: <FileLogPage />,
  },
  [SettingsPath.EVENTLOGS]: {
    path: settingsRoutes[SettingsPath.EVENTLOGS],
    element: <EventLogPage />,
  },
  [SettingsPath.USERS]: {
    path: settingsRoutes[SettingsPath.USERS],
    element: <UsersPage />,
  },
  [SettingsPath.MOYSKLAD]: {
    path: settingsRoutes[SettingsPath.MOYSKLAD],
    element: <MoySkladSettingsPage />,
  },
  [SettingsPath.OZON]: {
    path: settingsRoutes[SettingsPath.OZON],
    element: <OzonSettingsPage />,
  },
  [SettingsPath.YANDEX]: {
    path: settingsRoutes[SettingsPath.YANDEX],
    element: <YandexPage />,
  },
  [SettingsPath.WILDBERRIES]: {
    path: settingsRoutes[SettingsPath.WILDBERRIES],
    element: <WildberriesPage />,
  },
  [SettingsPath.MEGAMARKET]: {
    path: settingsRoutes[SettingsPath.MEGAMARKET],
    element: <MegaMarketPage />,
  },
};
