import axios from 'axios';
import { $api } from 'shared/api/api';
import { User } from 'entities/User';
import { getToken, storeToken } from '../token';

interface AuthResponse {
    token: string;
}

export const refresh = async () => {
  const response = await axios.post<AuthResponse>(
    '/api/auth/refresh',
    { clientId: 'sd' },
    { withCredentials: true },
  );

  if (!response.data) {
    throw new Error('empty resposne');
  }

  const { token } = response.data;

  storeToken(token);
};

export const login = async (username: string, password: string) => {
  const data = { login: username, password, clientId: 'sd' };
  const response = await axios.post<AuthResponse>('/api/auth/login', data);

  const { token } = response.data;
  console.log(token);
  storeToken(token);
  console.log(getToken());
};

export const getCurrentUser = async () => {
  const response = await $api.get<User>('/api/auth/currentUser');
  return response.data;
};
