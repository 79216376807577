import classNames from 'classnames';
import cl from './Loader.module.css';

interface LoaderProps {
    className?: string;
}

export const Loader = ({ className }: LoaderProps) => (
  <div className={classNames(cl.Loader, {}, [className])}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
