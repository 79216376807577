import { DateRangeValue } from 'shared/types/DateRangeValue';
import { dateRangeStateBuilder, dateRangeStateBuilderByMode } from './dateRangeStateBuilder';
import { DateRangeMode } from '../types/DateRangeMode';

export const getInitialState = (defaultMode: DateRangeMode, value?: DateRangeValue) => {
  if (value && !value.isEmpty) {
    const dayDiff = value.to.diff(value.from, 'day');
    const weekDiff = value.to.diff(value.from, 'week');
    const monthDiff = value.to.diff(value.from, 'month');

    if (monthDiff === 11) {
      return dateRangeStateBuilder(DateRangeMode.Year, value);
    }

    if (weekDiff >= 3) {
      return dateRangeStateBuilder(DateRangeMode.Month, value);
    }

    if (dayDiff === 6) {
      return dateRangeStateBuilder(DateRangeMode.Week, value);
    }

    return dateRangeStateBuilder(DateRangeMode.Day, value);
  }

  return dateRangeStateBuilderByMode(defaultMode);
};
