import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  failure, initial, pending, success,
} from '@devexperts/remote-data-ts';
import { WbSettings } from 'entities/Settings/Wildberries';
import { syncWbOrders } from '../services/syncWbOrders';
import { syncWbStocks } from '../services/syncWbStocks';
import { syncWbPrices } from '../services/syncWbPrices';
import { fetchWbSettings } from '../services/fetchWbSettings';
import { WbSettingsSchema } from '../types/WbSettingsSchema';
import { updateWbSettings } from '../services/updateWbSettings';

const initialState: WbSettingsSchema = {
  data: initial,
  updateData: initial,
};

export const wbSettingsSlice = createSlice({
  name: 'wbSettings',
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<WbSettings>) => {
      state.formData = action.payload;
    },
    updateFormData: (state, action: PayloadAction<Partial<WbSettings>>) => {
      state.formData = { ...state.formData, ...action.payload } as WbSettings;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWbSettings.pending, (state) => {
        state.data = pending;
      })
      .addCase(fetchWbSettings.fulfilled, (state, action) => {
        state.data = success(action.payload);
        state.formData = action.payload;
      })
      .addCase(fetchWbSettings.rejected, (state, action) => {
        state.data = failure(action.payload || '');
      })

      .addCase(updateWbSettings.pending, (state) => {
        state.updateData = pending;
      })
      .addCase(updateWbSettings.fulfilled, (state, action) => {
        state.updateData = success(undefined);
      })
      .addCase(updateWbSettings.rejected, (state, action) => {
        state.updateData = failure(action.payload ?? '');
      })

      .addCase(syncWbOrders.pending, (state) => {
        state.updateData = pending;
      })
      .addCase(syncWbOrders.fulfilled, (state, action) => {
        state.updateData = success(undefined);
      })
      .addCase(syncWbOrders.rejected, (state, action) => {
        state.updateData = failure(action.payload ?? '');
      })

      .addCase(syncWbPrices.pending, (state) => {
        state.updateData = pending;
      })
      .addCase(syncWbPrices.fulfilled, (state, action) => {
        state.updateData = success(undefined);
      })
      .addCase(syncWbPrices.rejected, (state, action) => {
        state.updateData = failure(action.payload ?? '');
      })

      .addCase(syncWbStocks.pending, (state) => {
        state.updateData = pending;
      })
      .addCase(syncWbStocks.fulfilled, (state, action) => {
        state.updateData = success(undefined);
      })
      .addCase(syncWbStocks.rejected, (state, action) => {
        state.updateData = failure(action.payload ?? '');
      });
  },
});

export const { actions: wbSettingsActions } = wbSettingsSlice;
export const { reducer: wbSettingsReducer } = wbSettingsSlice;
