/* eslint-disable no-bitwise */
import { DateRangeSupportedModes } from '../types/DateRangeSupportedModes';
import { DateRangeMode } from '../types/DateRangeMode';

export const isDateRangeModeSupported = (dateRangeSupportedModes: DateRangeSupportedModes, mode: DateRangeMode) => {
  switch (mode) {
    case DateRangeMode.Year:
      return (dateRangeSupportedModes & mode) === DateRangeSupportedModes.Year;
    case DateRangeMode.Month:
      return (dateRangeSupportedModes & mode) === DateRangeSupportedModes.Month;
    case DateRangeMode.Week:
      return (dateRangeSupportedModes & mode) === DateRangeSupportedModes.Week;
    case DateRangeMode.Day:
      return (dateRangeSupportedModes & mode) === DateRangeSupportedModes.Day;
    default: return false;
  }
};
