import React from 'react';
import { DateRangeSupportedModes } from '../../types/DateRangeSupportedModes';
import { isDateRangeModeSupported } from '../../lib/isDateRangeModeSupported';
import { DateRangeMode } from '../../types/DateRangeMode';
import cl from './DateRange2ModeSelector.module.css';

interface DateRange2ModeSelectorProps {
  onChange: (mode: DateRangeMode) => void;
  supportedModes: DateRangeSupportedModes;
}

export const DateRange2ModeSelector = ({ onChange, supportedModes }: DateRange2ModeSelectorProps) => (
  <div className={cl.DateRange2ModeSelector}>
    <span className={cl.label}>Период:</span>
    <div className={cl.items}>
      {isDateRangeModeSupported(supportedModes, DateRangeMode.Day) && (
      <button
        type="button"
        className={cl.item}
        onClick={() => onChange(DateRangeMode.Day)}
      >
        сег
      </button>
      )}
      {isDateRangeModeSupported(supportedModes, DateRangeMode.Week) && (
      <button
        type="button"
        className={cl.item}
        onClick={() => onChange(DateRangeMode.Week)}
      >
        нед
      </button>
      )}
      {isDateRangeModeSupported(supportedModes, DateRangeMode.Month) && (
      <button
        type="button"
        className={cl.item}
        onClick={() => onChange(DateRangeMode.Month)}
      >
        мес
      </button>
      )}
      {isDateRangeModeSupported(supportedModes, DateRangeMode.Year) && (
        <button
          type="button"
          className={cl.item}
          onClick={() => onChange(DateRangeMode.Year)}
        >
          год
        </button>
      )}
    </div>
  </div>
);
