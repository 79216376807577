import { DateRangeValue } from 'shared/types/DateRangeValue/';

export const dateRangeValueToParams = (value: DateRangeValue) => {
  if (value.isEmpty) return { from: undefined, to: undefined };

  return {
    from: value.from.format('YYYY-MM-DD'),
    to: value.to.format('YYYY-MM-DD'),
  };
};
