import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuItemType } from 'entities/Menu/model/types/MenuItemType';
import { MenuScheme } from 'entities/Menu/model/types/MenuScheme';

const initialState: MenuScheme = {};

const menuSlice = createSlice({
  name: 'widgets/menu',
  initialState,
  reducers: {
    setMenuItem: (state, action: PayloadAction<MenuItemType>) => {
      state.type = action.payload;
    },
    resetMenuItem: (state) => {
      state.type = undefined;
    },
  },
});

export const { actions: menuActions } = menuSlice;
export const { reducer: menuReducer } = menuSlice;
