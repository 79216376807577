import React, { useEffect } from 'react';
import classes from 'widgets/Header/ui/Header/Header.module.css';
import logo from 'shared/images/header/logo.png';
import User from 'widgets/Header/ui/User/User';
import Menu from 'widgets/Header/ui/Menu/Menu';
import SubMenu from 'widgets/Header/ui/SubMenu/SubMenu';
import { useLocation } from 'react-router-dom';
import { menuActions } from 'entities/Menu';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { menuItems } from '../../config/menuItems';

export function Header() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    for (let i = 0; i < menuItems.length; i += 1) {
      const item = menuItems[i];
      if (location.pathname.startsWith(item.path)) {
        dispatch(menuActions.setMenuItem(item.type));
        return;
      }
    }
    dispatch(menuActions.resetMenuItem());
  }, [dispatch, location.pathname]);

  return (
    <header className={classes.header}>
      <div className={classes.mainRow}>
        <img className={classes.logo} src={logo} alt="Логотип" />
        <Menu />
        <User />
      </div>
      <SubMenu />
    </header>
  );
}
