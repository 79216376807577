import { RouteProps } from 'react-router-dom';
import { WbPath, wbRoutes } from 'shared/config/routeConfig/wbRoutes';
import { WbRealizationReportsPage } from 'pages/Wb';

export const wbRoutesConfig: Record<WbPath, RouteProps> = {
  [WbPath.REALIZATION_REPORTS]: {
    path: wbRoutes[WbPath.REALIZATION_REPORTS],
    element: <WbRealizationReportsPage />,
  },
};
