import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  failure, initial, pending, success,
} from '@devexperts/remote-data-ts';
import { DateRangeMode, dateRangeValueBuilder } from 'shared/ui/DateRange2';
import { OrdersFilter } from '../types/OrdersFilter';
import { fetchOrders } from '../services/fetchOrders';
import { OrdersSchema } from '../types/OrdersSchema';

const initialState: OrdersSchema = {
  data: initial,
  value: undefined,
  filter: {
    dateRange: dateRangeValueBuilder(DateRangeMode.Month),
    page: 1,
  },
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<Partial<OrdersFilter>>) => {
      const filterUpdate = action.payload;
      if (!filterUpdate.page) {
        filterUpdate.page = 1;
      }
      state.filter = { ...state.filter, ...filterUpdate };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.data = pending;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.data = success(action.payload);
        state.value = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.data = failure(action.payload || '');
      });
  },
});

export const { actions: ordersActions } = ordersSlice;
export const { reducer: ordersReducer } = ordersSlice;
