import dayjs, { Dayjs } from 'dayjs';
import { DateRangeValue, emptyDateRangeValue } from 'shared/types/DateRangeValue';
import { DateRangeMode } from '../types/DateRangeMode';

interface dateRangeValueBuilderOptions {
  prev?: number;
  next?: number;
}

export function dateRangeValueBuilder(mode: DateRangeMode, options?:dateRangeValueBuilderOptions): DateRangeValue {
  let from: Dayjs;
  let to: Dayjs;

  switch (mode) {
    case DateRangeMode.Day:
      from = dayjs().startOf('day');
      to = from.add(1, 'day');
      break;
    case DateRangeMode.Week:
      from = dayjs().startOf('week');
      to = dayjs().startOf('week').add(1, 'w').subtract(1, 'm');
      break;
    case DateRangeMode.Month:
      from = dayjs().startOf('month');
      to = dayjs().startOf('month').add(1, 'M').subtract(1, 'm');
      break;
    case DateRangeMode.Year:
      from = dayjs().startOf('year');
      to = dayjs().startOf('year').add(1, 'y').subtract(1, 'm');
      break;
    case DateRangeMode.None:
    default:
      return emptyDateRangeValue;
  }

  let range: DateRangeValue = { isEmpty: false, from, to };
  if (options) {
    if (options.prev) {
      for (let i = 0; i < options.prev; i += 1) {
        range = prevDateRangeValue(mode, range);
      }
    }
  }

  return range;
}

export function nextDateRangeValue(mode: DateRangeMode, range: DateRangeValue): DateRangeValue {
  let from: Dayjs;
  let to: Dayjs;

  if (range.isEmpty) {
    return range;
  }

  switch (mode) {
    case DateRangeMode.Day:
      from = range.from.add(1, 'd');
      to = range.to.add(1, 'd');
      break;
    case DateRangeMode.Week:
      from = range.from.add(1, 'w');
      to = range.to.add(1, 'w');
      break;
    case DateRangeMode.Month:
      from = range.from.add(1, 'M');
      to = range.to.add(1, 'M');
      break;
    case DateRangeMode.Year:
      from = range.from.add(1, 'y');
      to = range.to.add(1, 'y');
      break;
    default:
      return range;
  }

  return { isEmpty: false, from, to };
}

export function prevDateRangeValue(mode: DateRangeMode, range: DateRangeValue): DateRangeValue {
  let from: Dayjs;
  let to: Dayjs;

  if (range.isEmpty) {
    return range;
  }

  switch (mode) {
    case DateRangeMode.Day:
      from = range.from.add(-1, 'd');
      to = range.to.add(-1, 'd');
      break;
    case DateRangeMode.Week:
      from = range.from.add(-1, 'w');
      to = range.to.add(-1, 'w');
      break;
    case DateRangeMode.Month:
      from = range.from.add(-1, 'M');
      to = from.add(1, 'M').add(-1, 'ms');
      // to = state.value.to.add(-1, 'M');
      break;
    case DateRangeMode.Year:
      from = range.from.add(-1, 'y');
      to = range.to.add(-1, 'y');
      break;
    default:
      return range;
  }

  return { isEmpty: false, from, to };
}
