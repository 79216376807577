import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'app/App';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'app/providers/StoreProvider';
import { AuthProvider } from 'app/providers/AuthProvider/AuthProvider';
import './shared/extensions/yup/index';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <StoreProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </StoreProvider>
  </BrowserRouter>,
);
