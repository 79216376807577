import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JWT_LOCALSTORAGE_KEY } from 'shared/const/localstorage';
import { loadUser } from '../services/loadUser';
import { UserSchema } from '../types/userSchema';
import { User } from '../types/User';

const initialState: UserSchema = {
  isLoading: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    logout: (state) => {
      state.user = undefined;
      state.isLoading = false;
      state.error = undefined;
      localStorage.removeItem(JWT_LOCALSTORAGE_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUser.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
