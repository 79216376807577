import { AxiosError } from 'axios';

export function getError(error: any): string {
  if (error && error.error && error.error.errors) {
    return error.error.errors[0].description;
  }

  if (error instanceof AxiosError) {
    const { response } = error;

    if (response?.status === 404) {
      return 'Ресурс не найден';
    }

    if (response?.status === 403) {
      return 'Доступ не разрешен';
    }

    const data = response?.data;
    if (data && data.errors) {
      if (response?.status === 400) {
        const error = Object.values(data.errors)[0] as any;
        return error[0] as string;
      }

      return data.errors[0]?.description;
    }
  }

  if (error instanceof TypeError) {
    return `Internal error ${error.message}`;
  }

  const { response } = error;
  if (response.data && response.data.includes('Proxy error')) {
    return 'Ошибка прокси';
  }

  return 'Неизвестная ошибка';
}
