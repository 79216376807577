import * as Yup from 'yup';
import { isValidCron } from 'cron-validator';

declare module 'yup' {
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    cron(): this;
    cron2(enabledName?: string): this;
  }
}

const cronValueEmptyMessage = 'Нужно заполнить cron-значение';
const cronValueInvalidMessage = 'Невалидное cron-значение';

Yup.addMethod(Yup.string, 'cron', function () {
  return this.test({
    name: 'cron',
    test: (value: string | undefined | null, context) => {
      if (!value) return context.createError({ message: cronValueEmptyMessage });
      if (!isValidCron(value)) return context.createError({ message: cronValueInvalidMessage });
      return true;
    },
    message: 'sdfsdf',
  });
});

// @ts-ignore
Yup.addMethod(Yup.string, 'cron2', function (enabledName?: string) {
  if (enabledName) {
    return this.when(enabledName, {
      is: true,
      then: (schema) => schema
        .cron()
        .required(cronValueEmptyMessage),
    }).when(enabledName, {
      is: false,
      then: (schema) => schema.nullable(),
    });
  }

  return this.cron().required(cronValueEmptyMessage);
});
