import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { createReduxStore } from 'app/providers/StoreProvider/config/store';
import { StateSchema } from 'app/providers/StoreProvider/config/StateSchema';

interface StoreProviderProps {
    children?: ReactNode;
    initialState?: StateSchema;
}

export function StoreProvider(props: StoreProviderProps) {
  const {
    children,
    initialState,
  } = props;

  // const navigate = useNavigate();
  const store = createReduxStore(initialState, undefined);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}
