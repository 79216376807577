import { NavLink } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import cl from './SubMenuItem.module.css';

interface SubMenuItemProps {
    to: string;
    name: string;
}

export const SubMenuItem = ({ to, name }: SubMenuItemProps) => (
  <NavLink
    className={({ isActive }) => classNames(
      cl.SubMenuItem,
      isActive && cl.SubMenuItemActive,
    )}
    to={to}
  >
    {name}
  </NavLink>
);
