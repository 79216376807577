import { Dayjs } from 'dayjs';

export type DateRangeValue = DateRangeValueWithValue | EmptyDateRangeValue;

export const emptyDateRangeValue: EmptyDateRangeValue = { isEmpty: true };

export interface EmptyDateRangeValue {
    isEmpty: true;
}

export interface DateRangeValueWithValue {
    isEmpty: false;
    from: Dayjs,
    to: Dayjs,
}
